import type { Motif, MotifComponentProps } from '@snapchat/snap-design-system-marketing';
import {
  Black,
  buttonFontVars,
  buttonStandardVars,
  contrastBlackButtonVars,
  contrastYellowButtonVars,
  defaultMotif,
  Gray,
  mergeMotifs,
  MotifComponent,
  MotifScheme,
  Plain,
  Yellow,
} from '@snapchat/snap-design-system-marketing';

const buttonStandardVarsOverride = {
  ...buttonStandardVars,
  '--button-hover-transform': 'translateY(0)',
  '--button-active-transform': 'translateY(0)',
  '--button-hover-shadow': 'none',
  '--button-active-shadow': 'none',
};

const blackPrimaryGraySecondary: MotifComponentProps<MotifComponent.BUTTON> = {
  '--button-primary-bg-color': Plain.BLACK,
  '--button-primary-hover-bg-color': Black.V100,
  '--button-primary-border-color': Plain.BLACK,
  '--button-primary-hover-border-color': Black.V100,
  '--button-primary-fg-color': Plain.WHITE,
  '--button-secondary-bg-color': Gray.V150,
  '--button-secondary-hover-bg-color': Gray.V250,
  '--button-secondary-border-color': Gray.V150,
  '--button-secondary-hover-border-color': Gray.V250,
  '--button-secondary-fg-color': Plain.BLACK,
  '--button-flat-fg-color': Plain.BLACK,
  ...buttonFontVars,
  ...buttonStandardVarsOverride,
} as const;

const blackPrimaryWhiteSecondary: MotifComponentProps<MotifComponent.BUTTON> = {
  '--button-primary-bg-color': Plain.BLACK,
  '--button-primary-border-color': Plain.BLACK,
  '--button-primary-hover-bg-color': Black.V100,
  '--button-primary-hover-border-color': Black.V100,
  '--button-primary-fg-color': Plain.WHITE,
  '--button-secondary-bg-color': Plain.WHITE,
  '--button-secondary-hover-bg-color': Gray.V250,
  '--button-secondary-border-color': Plain.WHITE,
  '--button-secondary-hover-border-color': Gray.V250,
  '--button-secondary-fg-color': Plain.BLACK,
  '--button-flat-fg-color': Plain.BLACK,
  ...buttonFontVars,
  ...buttonStandardVarsOverride,
} as const;

/** Motif for the ForBusiness CTA test. */
export const forBusinessMotif: Motif = mergeMotifs(defaultMotif, {
  name: 'ForBusiness',
  [MotifScheme.DEFAULT]: {
    name: 'Yellow Background',
    [MotifComponent.BUTTON]: {
      ...contrastYellowButtonVars,
      ...buttonStandardVarsOverride,
      '--button-primary-hover-bg-color': Black.V100,
      '--button-primary-hover-border-color': Black.V100,
      '--button-secondary-hover-bg-color': Gray.V150,
      '--button-secondary-hover-border-color': Gray.V150,
    },
  },
  [MotifScheme.SECONDARY]: {
    name: 'Black Background',
    [MotifComponent.BUTTON]: {
      ...contrastBlackButtonVars,
      ...buttonStandardVarsOverride,
      '--button-primary-hover-bg-color': Yellow.V200,
      '--button-primary-hover-border-color': Yellow.V200,
      '--button-secondary-hover-bg-color': Gray.V150,
      '--button-secondary-hover-border-color': Gray.V150,
    },
  },
  [MotifScheme.TERTIARY]: {
    name: 'White background with Black Primary Button',
    [MotifComponent.BUTTON]: blackPrimaryGraySecondary,
  },
  [MotifScheme.QUATERNARY]: {
    name: 'Gray background with Black Primary Button',
    [MotifComponent.BUTTON]: blackPrimaryWhiteSecondary,
  },
});
