import type { MapOf, StepActionsProps } from '@snapchat/snap-design-system-marketing';
import {
  BlockNavigation as BlockNavigationSDS,
  ProgressBar as ProgressBarSDS,
} from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';

import { ConsumerContext } from '../../../../components/ConsumerContextProvider';
import { Config } from '../../../../config';
import { logEvent, SubscribedEventType } from '../../../../helpers/logging';
import { UserAction } from '../../../../types/events';
import { QuestionnaireIntro } from '../QuestionnaireIntro';
import { QuestionnaireQuestion } from '../QuestionnaireQuestion';
import { QuestionnaireResults } from '../QuestionnaireResults/QuestionnaireResults';
import { containerCss, questionnaireFooterCss } from './styles';
import type { AnswerOptionProps, QuestionnaireProps, QuestionProps } from './types';

const questionnaireIntroStepCount = 1;
const showResultsPage = 'showResultsPage';
const urlAnswersProp = 'answers';

const questionnaireNavigationEvent = (label: string) => {
  logEvent({
    subscribedEventType: SubscribedEventType.USER_INTERACTION,
    eventCategory: 'Questionnaire Navigation',
    eventAction: UserAction.Click,
    eventLabel: label,
  });
};

const questionnaireAnswerClickEvent = (label: string) => {
  logEvent({
    subscribedEventType: SubscribedEventType.USER_INTERACTION,
    eventCategory: 'Questionnaire Answer Click',
    eventAction: UserAction.Click,
    eventLabel: label,
  });
};

export const Questionnaire: FC<QuestionnaireProps> = props => {
  const {
    title,
    body,
    image,
    startLabel,
    nextLabel,
    backLabel,
    skipLabel,
    submitLabel,
    questions: questionsData,
  } = props;

  const [currentStep, setCurrentStep] = useState(0);
  const [questions, setQuestions] = useState(questionsData);
  const [answerIDs, setAnswerIDs] = useState(['']);
  const [showResults, setShowResults] = useState(false);
  const { setUrlParams, getUrlParams } = useContext(ConsumerContext);

  const totalSteps = questions.length;
  const isLastStep = currentStep === totalSteps;
  const isFirstStep = currentStep === 0;
  const currentQuestionIndex = currentStep - questionnaireIntroStepCount;
  const currentQuestion = questions[currentQuestionIndex]!;

  const handleGoBack = useCallback(() => {
    showResults && setShowResults(false);

    if (getUrlParams) {
      const newStep = Number(getUrlParams().step);
      !isNaN(newStep) && setCurrentStep(newStep);
    }
  }, [showResults, getUrlParams, setCurrentStep]);

  useEffect(() => {
    window.addEventListener('popstate', handleGoBack);

    return () => {
      window.removeEventListener('popstate', handleGoBack);
    };
  }, [currentStep, handleGoBack]);

  const goNext = () => {
    if (isLastStep) {
      questionnaireNavigationEvent('Go to questionnaire results');
      updateSelectedAnswers(answerIDs, true);
      return;
    }

    questionnaireNavigationEvent(
      isFirstStep
        ? 'Start the questionnaire'
        : `Go to question {${currentQuestion.id}} (${currentStep} of ${totalSteps})`
    );
    const newStep = currentStep + 1;
    setCurrentStep(newStep);
    updateUrlParams({ step: newStep.toString() });
  };

  const goBack = () => {
    if (isFirstStep) {
      return;
    }

    questionnaireNavigationEvent(
      currentStep === 1 ? 'Go to questionnaire intro' : 'Go to previous questionnaire question'
    );
    const newStep = currentStep - 1;
    setCurrentStep(newStep);
    updateUrlParams({ step: newStep.toString() });
  };

  const getStepDefinition = () => {
    const requiredQuestion = currentQuestion.required;
    const answerSelected = currentQuestion.answers.some(answer => answer.isSelected);

    const stepActions: StepActionsProps = {
      nextButton: {
        onClick: goNext,
        label: isLastStep ? submitLabel : nextLabel,
        showArrow: !isLastStep,
        isDisabled: requiredQuestion && !answerSelected,
      },
      previousButton: {
        onClick: goBack,
        label: backLabel,
        showArrow: true,
        isDisabled: isFirstStep,
      },
      skipButton:
        !skipLabel || isLastStep || requiredQuestion
          ? undefined
          : {
              onClick: goNext,
              label: skipLabel,
            },
    };

    return stepActions;
  };

  const updateUrlWithChosenAnswers = (questions: QuestionProps[]) => {
    const answersIds: string[] = [];

    questions.forEach(question => {
      question.answers.forEach(answer => {
        answer.isSelected && answersIds.push(answer.id);
      });
    });
    updateSelectedAnswers(answersIds);
  };

  const updateUrlParams = (params: MapOf<string | undefined>) => {
    if (setUrlParams && getUrlParams) {
      setUrlParams({ ...getUrlParams(), ...params });
    }
  };

  const updateSelectedAnswers = (newAnswerIDs: string[], showResultsPage?: boolean) => {
    const answersHasChanged = newAnswerIDs.some(id => !answerIDs.includes(id));

    if (answersHasChanged) {
      const questionId = currentQuestion.id;
      const answers = newAnswerIDs.map(id => `{${id}}`).join(', ');
      const eventDetails = `question {${questionId}} answer(s): ${answers}`;
      questionnaireAnswerClickEvent(eventDetails);
    }

    setAnswerIDs(newAnswerIDs);
    const urlParams: MapOf<string | undefined> = { [urlAnswersProp]: newAnswerIDs.join(',') };

    if (showResultsPage) {
      setShowResults(true);
      urlParams.step = String(showResultsPage);
    }

    updateUrlParams(urlParams);
  };

  const handleQuestionAnswerChange = (updatedAnswers: AnswerOptionProps[]) => {
    questions[currentQuestionIndex]!.answers = updatedAnswers;
    setQuestions(questions);
    updateUrlWithChosenAnswers(questions);
  };

  const renderComponent = () => {
    if (isFirstStep) {
      return (
        <QuestionnaireIntro
          imageSrcUrl={image}
          title={title}
          description={body}
          buttonText={startLabel}
          onClick={goNext}
        />
      );
    } else {
      return (
        <>
          <QuestionnaireQuestion
            {...currentQuestion}
            onAnswerChange={handleQuestionAnswerChange}
            currentStep={currentStep}
            totalSteps={totalSteps}
          />
          <div className={questionnaireFooterCss}>
            <BlockNavigationSDS step={getStepDefinition()} />
            <ProgressBarSDS currentStep={currentStep} totalSteps={totalSteps} />
          </div>
        </>
      );
    }
  };

  const isRenderingOnServerSide = !Config.isClient;

  if (isRenderingOnServerSide || !getUrlParams) {
    return null;
  }

  const { [urlAnswersProp]: answers, step } = getUrlParams();

  if (step === showResultsPage || showResults) {
    const answersIds = answers?.split(',');

    if (!getUrlParams) {
      return null;
    }

    return <QuestionnaireResults answersIds={answersIds as string[]} />;
  }

  return <section className={containerCss}>{renderComponent()}</section>;
};
