import { cx } from '@emotion/css';
import { BackgroundColor } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useContext } from 'react';

import { Anchor } from '../../../../components/Anchor';
import { ConsumerContext } from '../../../../components/ConsumerContextProvider';
import type { ContentfulIdVariable } from '../../../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../../../hooks/useContentfulQuery';
import { UserAction } from '../../../../types/events';
import {
  persistentCallToActionBlackBgCss,
  persistentCallToActionButtonBlackCss,
  persistentCallToActionButtonGrayCss,
  persistentCallToActionButtonStylesCss,
  persistentCallToActionButtonWhiteCss,
  persistentCallToActionButtonYellowCss,
  persistentCallToActionGrayBgCss,
  persistentCallToActionWhiteBgCss,
  persistentCallToActionWrapperCss,
  persistentCallToActionYellowBgCss,
} from './PersistentCallToAction.styles';
import { query } from './query';
import type { PersistentCallToActionProps } from './types';

export const PersistentCallToAction: FC<{ id: string }> = ({ id }) => {
  const { logEvent } = useContext(ConsumerContext);
  const { data } = useContentfulQuery<PersistentCallToActionProps, ContentfulIdVariable>(query, {
    variables: { id },
  });

  if (!data?.mobileCta) return null;

  const { analytics, backgroundColor, buttonColor, body, url } = data.mobileCta;

  return (
    <div
      className={cx(persistentCallToActionWrapperCss, {
        [persistentCallToActionYellowBgCss]: backgroundColor === BackgroundColor.Yellow,
        [persistentCallToActionWhiteBgCss]: backgroundColor === BackgroundColor.White,
        [persistentCallToActionGrayBgCss]: backgroundColor === BackgroundColor.Gray,
        [persistentCallToActionBlackBgCss]: backgroundColor === BackgroundColor.Black,
      })}
    >
      <Anchor
        className={cx(persistentCallToActionButtonStylesCss, {
          [persistentCallToActionButtonYellowCss]: buttonColor === BackgroundColor.Yellow,
          [persistentCallToActionButtonWhiteCss]: buttonColor === BackgroundColor.White,
          [persistentCallToActionButtonGrayCss]: buttonColor === BackgroundColor.Gray,
          [persistentCallToActionButtonBlackCss]: buttonColor === BackgroundColor.Black,
        })}
        href={url}
        onClick={() => analytics && logEvent?.({ type: UserAction.Click, label: analytics.label })}
      >
        {body}
      </Anchor>
    </div>
  );
};
