import { css } from '@emotion/css';
import {
  Black,
  Gray,
  mobileMediaQuery,
  Spacing,
  White,
  Yellow,
  ZIndex,
} from '@snapchat/snap-design-system-marketing/src/constants';

export const persistentCallToActionWrapperCss = css`
  border-top: 1px solid;
  display: none;

  ${mobileMediaQuery} {
    bottom: 0;
    display: block;
    padding: ${Spacing.XS}px;
    position: sticky;
    z-index: ${ZIndex.MOBILE_CTA};
  }
`;

export const persistentCallToActionYellowBgCss = css`
  background-color: ${Yellow.V100};
  border-top-color: ${Black.V50};
`;

export const persistentCallToActionWhiteBgCss = css`
  background-color: ${White};
  border-top-color: ${Gray.V250};
`;

export const persistentCallToActionGrayBgCss = css`
  background-color: ${Gray.V100};
  border-top-color: ${Gray.V250};
`;

export const persistentCallToActionBlackBgCss = css`
  background-color: ${Black.V200};
  border-top-color: ${Black.V50};
`;

export const persistentCallToActionButtonStylesCss = css`
  align-items: center;
  border-radius: 4px;
  display: flex;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  min-height: 54px;
  padding: ${Spacing.XS}px ${Spacing.MD}px;
  text-align: center;
  text-decoration: none;
`;

export const persistentCallToActionButtonYellowCss = css`
  background-color: ${Yellow.V100};
  color: ${Black.V200};
`;

export const persistentCallToActionButtonWhiteCss = css`
  background-color: ${White};
  color: ${Black.V200};
`;

export const persistentCallToActionButtonGrayCss = css`
  background-color: ${Gray.V100};
  color: ${Black.V200};
`;

export const persistentCallToActionButtonBlackCss = css`
  background-color: ${Black.V200};
  color: ${White};
`;
